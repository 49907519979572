export default {
    Home: 'Inicio',

    Education: 'Educación',
    Development: 'Desarrollo',
    //
    'Working Experience': 'Experiencia Laboral',
    'Teaching Experience': 'Experiencia Docente',
    Languages: 'Idiomas',
    Bilingual: 'Bilingüe',
    Native: 'Nativo',
    English: 'Inglés',
    Spanish: 'Español',
    Japanese: 'Japonés',

    Present: 'Presente',

    moment: {
        monthsShort: 'Ene,Feb,Mar,Abr,May,Jun,Jul,Ago,Sep,Oct,Nov,Dic'.split(','),
    },
}
