import React, { useState } from 'react'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { keys, get } from 'lodash-es'
import moment from 'moment'

const resources = {
    en: { translation: require('./messages/en').default },
    es: { translation: require('./messages/es').default },
}

var translateFunction
let detectedLanguage = 'en'

var languageDetector = new LanguageDetector()
languageDetector.init(null, {
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
    lookupQuerystring: 'language',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',

    // cache user language
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'],
    //cookieMinutes: 10,
    //cookieDomain: 'myDomain'
    checkWhitelist: true,
})
var detect = languageDetector.detect.bind(languageDetector)

export const setLanguage = (lang) => {
    detectedLanguage = lang
    moment.locale(lang, get(resources, lang, resources.en).translation.moment)
}

languageDetector.detect = function(...args) {
    var lang = detect(...args) || ''
    lang = lang.split('-')[0]
    setLanguage(lang)
    return lang
}

var promise = i18next.use(languageDetector).init(
    {
        //debug: true,
        fallbackLng: 'en',
        load: 'languageOnly',
        resources,
    },
    (error, t) => {
        if (error) {
            console.error('Error initializing i18n module')
        } else {
            translateFunction = t
        }
    }
)

export const localeLanguage = (locale) => locale.split('-')[0]

export const isCurrentLanguage = (locale) => localeLanguage(locale) == i18next.language

export const languageContentFilter = ({ node }) => isCurrentLanguage(node.node_locale)

const filterData = (data) => {
    const localeFilterList = (list) =>
        list ? list.filter(({ locale }) => !locale || locale.includes(detectedLanguage)) : undefined

    const localeFilter = (value) => ({
        ...value,
        nodes: localeFilterList(value.nodes),
        edges: localeFilterList(value.edges),
    })

    return keys(data).reduce((all, key) => {
        const value = data[key]
        return { ...all, [key]: localeFilter(value) }
    }, {})
}

export const withI18n = (Component) => ({ data, ...props }) => {
    var [i18nInitialized, setI18nInitialized] = useState(false)

    promise.then(() => setI18nInitialized(true))

    return (
        <div>
            {i18nInitialized ? (
                <Component {...props} language={detectedLanguage} data={filterData(data)} />
            ) : null}
        </div>
    )
}

export const getLanguage = () => detectedLanguage
export const translate = (key) => translateFunction(key)

export default translate
