import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Barcode from 'react-barcode'
import { QRCode } from 'react-qr-svg'

import Markdown from 'react-markdown'

import { languageContentFilter } from '../i18n'

import styled, { css } from 'styled-components'

import { defaults, get, isNumber } from 'lodash'

const mobileBreakpoint = 'screen and (max-width: 767px)'

const DEFAULT_CURRENCY_SETTINGS = { invoiceCurrency: 'USD', payingInCurrency: 'USD' }

const Money = styled(({ className, value, currency }) => {
    const [whole, decimal] = parseFloat(value)
        .toFixed(2)
        .split('.')
    return (
        <span className={className + ' number'}>
            <span className="symbol">$</span>
            <span className="whole">{whole}</span>.<span className="decimal">{decimal}</span>
            {currency && <span className="currency"> {currency}</span>}
        </span>
    )
})`
    white-space: nowrap;

    .decimal {
        font-size: 0.9em;
    }
`

const globalStyle = `

  html {
    
    font-size: 42.5%;

    font-family: 'Open Sans', sans-serif;
    color: 1d1d1d;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
    margin: 0;
  }

  body {
    font-size: 1.4rem;
    margin: 0;
    padding: 0;

  }

  a {
      color: #333;

    &, &:visited, &:focus, &:active, &:hover {
        color: inherit;
    }
  }

  @media print {
      body {
          margin: 2cm;
      }
  }

  small {
    font-size: 0.9em;
  }
  
  @media screen {
    html {
      font-size: 62.5%;
      font-size: 10px;
    }

    body {
      padding: 8rem 10rem 14rem 10rem;
    }
  }

  @media ${mobileBreakpoint} {
    body {
        padding: 1rem 2rem 4rem 2rem;
    }
  }

  h1 { 
    margin: 0; 
    font-weight: 400;
    line-height: 1;
    font-size: 6rem;
  }
  @media ${mobileBreakpoint} {
    h1 {
        font-size: 3rem;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: #333;
  }

  h3, h4 {
    margin-bottom: 0.6em;
  }

  p {
    margin: 0.5em 0;
  }

  @media print {
    -webkit-print-color-adjust: exact;
  }
`

const InvoiceNumber = styled.span`
    color: #666;
    font-size: 2.6rem;

    @media ${mobileBreakpoint} {
        font-size: 1.6rem;
    }
`

const Content = styled.div`
    @media screen {
        max-width: 800px;
        margin: auto;
    }

    position: relative;
`

const Row = styled.div`
    ${({ withColumns, wrapReverse, noMargin }) =>
        withColumns
            ? `
        display: flex;
        flex-wrap: wrap${wrapReverse ? '-reverse' : ''};
        margin: 3rem -2rem -2rem -2rem;

        @media ${mobileBreakpoint} {
            margin-top: 2rem;

            &:first-child {
                margin-top: 1rem;
            }
        }

        `
            : `
        margin: 3rem 2rem;
        `}
        
        ${({ noMargin }) => noMargin && 'margin-top: 0; margin-bottom: 0;'}
    ${({ align }) => (align ? `text-align: ${align};` : '')}
`

const Column = styled.div`
  box-sizing: border-box;

  padding: 0 2rem 2rem 2rem;

  ${({ noMargin }) => (noMargin ? 'margin: 0;' : '')}

  ${({ size }) =>
      size
          ? `
    width: ${(size / 12) * 100}%;
  `
          : `
    
  `}

  @media ${mobileBreakpoint} {
    ${({ mobileSize, size }) =>
        mobileSize || size
            ? `
        width: ${((mobileSize || size) / 12) * 100}%;
    `
            : `
    
    `}  
  }


  ${({ align }) => (align ? `text-align: ${align};` : '')}
`

const Table = styled.div`
    display: table;
    width: 100%;

    margin: 2rem 0;

    @media ${mobileBreakpoint} {
        margin: 2rem 0;
    }
`

const TableRow = styled.div`
    display: table-row;
`

const cellMixin = css`
    display: table-cell;
    padding: 0.8rem 2rem;

    @media ${mobileBreakpoint} {
        padding: 0.6rem 1rem;
    }

    text-align: right;

    &:first-child {
        text-align: left;
    }
`

const TableHead = styled.div`
    ${cellMixin}
    color: #FEFEFE;
    border-color: #555;

    position: relative;

    @media ${mobileBreakpoint} {
        font-size: 0.85em;
    }

    &:first-child {
        border-left: 0;
    }

    &:last-child {
        border-right: 0;
    }

    background-color: #333;

    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`

const TableCell = styled.div`
    ${cellMixin}

    @media ${mobileBreakpoint} {
        font-size: 0.9em;
    }
`

const TableHeading = styled.div`
    display: table-header-group;
    font-weight: bold;
`

const TableFoot = styled.div`
    background-color: #eee;
    display: table-footer-group;
    font-weight: bold;
`

const TableBody = styled.div`
    display: table-row-group;
`

const Summary = styled.div``

const SummaryField = styled.div`
    padding: 0.8rem 2rem;

    @media ${mobileBreakpoint} {
        padding: 0.6rem 1rem;
    }

    display: flex;

    ${({ highlighted }) =>
        highlighted
            ? `
    background-color: rgba(0,0,0,0.1);
    border-radius: 0.8rem;
    font-weight: 700;

  `
            : ''}
`

const SummaryLabel = styled.div`
    margin-right: 10px;
    flex-grow: 1;
`

const SummaryValue = styled.div`
    text-align: right;
`

const InvoiceSummary = styled(Summary)`
    margin-left: auto;
    max-width: 18rem;
    font-size: 1.4em;
    font-weight: 700;
    margin-bottom: 6rem;

    @media ${mobileBreakpoint} {
        margin-bottom: 3rem;
    }

    & > * {
        padding: 0;
    }
`

const Title = styled.h3`
    color: #333;
    font-weight: 700;

    ${({ noMargin }) => noMargin && 'margin-top: 0; margin-bottom: 0;'}
`

const Text = styled.div`
    ${({ indented }) => (indented ? 'margin: 0.8rem 2rem 0.8rem 2rem;' : '')}
    ${({ align }) => (align ? `text-align: ${align};` : '')}

  margin-bottom: 2rem;

    @media ${mobileBreakpoint} {
        margin-bottom: 1rem;
    }
`

const Company = styled.div`
    margin-top: 0.5rem;
    margin-bottom: 3rem;

    @media ${mobileBreakpoint} {
        margin-bottom: 0rem;
    }
`

const CompanyLogo = styled.div`
    font-weight: 700;
    font-size: 4rem;

    @media ${mobileBreakpoint} {
        font-size: 2.6rem;
    }
`

const CompanyDescription = styled.div`
    color: #333;
    font-size: 2rem;

    @media ${mobileBreakpoint} {
        font-size: 1.5rem;
    }
`

const ScreenOnly = styled.div`
    @media print {
        display: none;
    }

    @media ${mobileBreakpoint} {
        display: none;
    }
`

const StampWatermark = styled.div`
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);

    border-radius: 2rem;
    border: 1rem solid ${({ color = 'black' }) => color};
    color: ${({ color = 'black' }) => color};

    padding: 0.5rem 3rem;
    text-align: center;

    font-size: 10rem;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;

    opacity: 0.5;
`

class InvoiceTemplate extends React.Component {
    render() {
        const invoice = get(this.props, 'data.allContentfulInvoice.edges', [])[0].node

        const {
            id,
            number,
            items = [],
            date,
            dueDate,
            paymentTerms,
            billTo,
            barcode,
            periodStart,
            periodEnd,
            type,
            attachments,
            status,
        } = invoice

        defaults(invoice, DEFAULT_CURRENCY_SETTINGS)

        const notes = get(invoice, 'notes.childMarkdownRemark.html')
        let terms = get(invoice, 'terms.childMarkdownRemark.html', '')

        if (paymentTerms == 'Paypal') {
            terms += `
        <p>
          Send by Paypal to https://www.paypal.me/arypbatista
        </p>
      `
        } else if (paymentTerms == 'Wiretransfer') {
            terms += `
        <p>
          Wiretransfer to bank account
        </p>
      `
        }

        const exchangeRate = parseFloat(invoice.exchangeRate)
        const siteTitle = get(this.props, 'data.site.siteMetadata.title')

        const { total, hasQuantity, hasRate } = items.reduce(
            ({ total, hasQuantity, hasRate }, item) => {
                return {
                    total: total + (isNumber(item.total) ? item.total : item.quantity * item.rate),
                    hasQuantity: hasQuantity || isNumber(item.quantity),
                    hasRate: hasRate || isNumber(item.rate),
                }
            },
            { total: 0, hasQuantity: false, hasRate: false }
        )

        const balanceDue = total

        return (
            <Content>
                <Helmet>
                    <title>{`Invoice #${number}`}</title>
                    <style>{globalStyle}</style>
                    <link
                        href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700&display=swap"
                        rel="stylesheet"
                    />
                </Helmet>

                {status == 'Paid' ? <StampWatermark color="red">Paid</StampWatermark> : null}

                <Row withColumns>
                    <Column mobileSize={6} size={7}>
                        <Company>
                            <CompanyLogo>Ary Pablo Batista</CompanyLogo>
                            <CompanyDescription>Software Engineer</CompanyDescription>
                        </Company>
                    </Column>
                    <Column mobileSize={6} size={5} noMargin>
                        <Text align="right" indented>
                            <h1 style={{ textTransform: 'uppercase' }}>{type}</h1>
                            <InvoiceNumber># {number}</InvoiceNumber>
                        </Text>
                    </Column>
                </Row>
                <Row withColumns wrapReverse style={{ marginTop: 0 }}>
                    <Column mobileSize={12} size={7}>
                        <Title style={{ marginTop: 0 }}>From:</Title>
                        <Text indented>
                            <strong>Ary Pablo Batista</strong>
                            <br />
                            <span>arypbatista@gmail.com</span>
                            <br />
                            <small>
                                <span>969 148th Street</span>
                                <br />
                                <span>B1880DRS Berazategui</span>
                                <br />
                                <span>Buenos Aires, Argentina</span>
                                <br />
                            </small>
                        </Text>

                        <Title>Bill To:</Title>
                        <Text indented>
                            <strong>{billTo.businessName}</strong>
                            <br />
                            <span>{billTo.email}</span>
                            <br />
                            {billTo.address ? (
                                <div dangerouslySetInnerHTML={{ __html: billTo.address }} />
                            ) : null}
                        </Text>
                    </Column>
                    <Column mobileSize={12} size={5}>
                        <Summary>
                            <SummaryField>
                                <SummaryLabel>Date:</SummaryLabel>
                                <SummaryValue>{date}</SummaryValue>
                            </SummaryField>

                            <SummaryField>
                                <SummaryLabel>Payment Terms:</SummaryLabel>
                                <SummaryValue>{paymentTerms}</SummaryValue>
                            </SummaryField>

                            <SummaryField>
                                <SummaryLabel>Due Date:</SummaryLabel>
                                <SummaryValue>{dueDate}</SummaryValue>
                            </SummaryField>

                            {periodStart ? (
                                <SummaryField>
                                    <SummaryLabel>Period:</SummaryLabel>
                                    <SummaryValue>
                                        from {periodStart}
                                        <br />
                                        to {periodEnd || date}
                                    </SummaryValue>
                                </SummaryField>
                            ) : null}

                            <SummaryField highlighted>
                                <SummaryLabel>Balance Due:</SummaryLabel>
                                <SummaryValue>
                                    <Money value={balanceDue} />
                                </SummaryValue>
                            </SummaryField>
                        </Summary>
                    </Column>
                </Row>

                <Row withColumns noMargin>
                    <Column mobileSize={12} size={12} noMargin>
                        <Title noMargin>
                            Currency: {invoice.invoiceCurrency}
                            {invoice.payingInCurrency != invoice.invoiceCurrency &&
                                ` | Paying in: ${invoice.payingInCurrency} | Exchange Rate: ${invoice.exchangeRate}`}
                        </Title>
                    </Column>
                </Row>

                <Table>
                    <TableHeading>
                        <TableRow>
                            <TableHead>Description</TableHead>
                            <TableHead>{hasQuantity ? 'Quantity' : ''}</TableHead>
                            <TableHead>{hasRate ? 'Rate' : ''}</TableHead>
                            <TableHead>Amount</TableHead>
                        </TableRow>
                    </TableHeading>
                    <TableBody>
                        {items.map((item, key) => (
                            <TableRow key={key}>
                                <TableCell>{item.description}</TableCell>

                                <TableCell>{isNumber(item.quantity) && item.quantity}</TableCell>

                                <TableCell>
                                    {isNumber(item.rate) && <Money value={item.rate} />}
                                </TableCell>

                                <TableCell>
                                    <Money
                                        value={
                                            isNumber(item.total)
                                                ? item.total
                                                : item.rate * item.quantity
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Row>
                    <InvoiceSummary>
                        <SummaryField>
                            <SummaryLabel>Total:</SummaryLabel>
                            <SummaryValue>
                                <Money value={total} currency={invoice.invoiceCurrency} />
                            </SummaryValue>
                        </SummaryField>

                        {invoice.payingInCurrency && (invoice.invoiceCurrency != invoice.payingInCurrency) && (
                            <SummaryField>
                                <SummaryLabel>Pay:</SummaryLabel>
                                <SummaryValue>
                                    <Money
                                        value={total * exchangeRate}
                                        currency={invoice.payingInCurrency}
                                    />
                                </SummaryValue>
                            </SummaryField>
                        )}
                    </InvoiceSummary>
                </Row>

                <ScreenOnly>
                    {attachments ? (
                        <Row withColumns>
                            <Column>
                                <Title>Attachments ({attachments.length})</Title>
                                {attachments.map((attachment, key) => (
                                    <a key={key} target="_blank" href={attachment.file.url}>
                                        {attachment.title} (
                                        {Math.round(attachment.file.details.size / 1024)} KB)
                                    </a>
                                ))}
                            </Column>
                        </Row>
                    ) : null}
                </ScreenOnly>

                <Row withColumns>
                    <Column style={{ paddingTop: paymentTerms == 'Paypal' ? '4rem' : '0' }}>
                        {notes && (
                            <div>
                                <Title>Notes:</Title>
                                <Text dangerouslySetInnerHTML={{ __html: notes }} />
                            </div>
                        )}

                        {terms && (
                            <div>
                                <Title>Terms:</Title>
                                <Text dangerouslySetInnerHTML={{ __html: terms }} />
                            </div>
                        )}

                        {barcode && (
                            <Barcode
                                value={barcode}
                                width={1.5}
                                height={60}
                                fontSize={16}
                                font="Open Sans"
                                textMargin={4}
                                format="ITF"
                            />
                        )}
                    </Column>

                    {paymentTerms == 'Paypal' ? (
                        <Column size={4} align="center">
                            <h3 style={{ marginTop: 0 }}>Scan to Pay</h3>
                            <QRCode
                                bgColor="#FFFFFF"
                                fgColor="#000000"
                                level="Q"
                                style={{ width: '19.2rem' }}
                                value={`https://www.paypal.me/arypbatista/${total}`}
                            />
                        </Column>
                    ) : null}

                    {paymentTerms == 'Wiretransfer' &&
                    (!invoice.payingInCurrency || invoice.payingInCurrency == 'USD') ? (
                        <Column size={12}>
                            <Text>
                                <Markdown
                                    source={`

### Wiretransfer Information

#### Beneficiary Information 
**Bank Name:** Banco BBVA Argentina S.A.  
**Swift Code:** BFRPARBA  
**Beneficiary Name:** Ary Pablo Batista  
**Account Number:** 0170338044000063231038  
**CUIT:** 20359424842
**Payment Concept:** Servicios de Informatica (S13)

#### Intermediary Bank Information  
**Bank Name:** Standard Chartered Bank  
**City:** New York  
**Swift Code:** SCBLUS33  
**ABA Routing:** 026002561  

#### Do you need extra information?
Drop me an email with your requirements at [arypbatista@gmail.com](mailto:arypbatista@gmail.com?subject=${encodeURIComponent(
                                        `Invoice # ${number} - Information required for Wiretransfer`
                                    )})

                `
                                        .replace(/\n +/g, '\n')
                                        .trim()}
                                />
                            </Text>
                        </Column>
                    ) : null}
                </Row>

                <ScreenOnly>
                    <Row align="center">
                        <a href="#" onClick={() => window.print()}>
                            Click here to print this invoice
                        </a>
                    </Row>
                </ScreenOnly>
            </Content>
        )
    }
}

export default InvoiceTemplate

export const pageQuery = graphql`
    query InvoiceByHash($id: String!) {
        site {
            siteMetadata {
                title
            }
        }

        allContentfulInvoice(filter: { contentful_id: { eq: $id } }) {
            edges {
                node {
                    node_locale
                    id: contentful_id
                    number
                    type
                    status
                    invoiceCurrency
                    exchangeRate
                    payingInCurrency

                    paymentTerms
                    date(formatString: "MMMM Do, YYYY")
                    dueDate(formatString: "MMMM Do, YYYY")

                    periodStart(formatString: "MMMM Do, YYYY")
                    periodEnd(formatString: "MMMM Do, YYYY")

                    billTo {
                        businessName
                        email
                    }

                    items {
                        description
                        quantity
                        rate
                        total
                    }
                    notes {
                        childMarkdownRemark {
                            html
                        }
                    }
                    terms {
                        childMarkdownRemark {
                            html
                        }
                    }
                    attachments {
                        title
                        file {
                            url
                            details {
                                size
                            }
                        }
                    }
                }
            }
        }
    }
`
